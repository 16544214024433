import { Component, OnInit } from '@angular/core';
import { Service } from '../client/service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  name: string;
  email: string;
  subject: string;
  message: string;

  result: boolean = false;
  resultMsg: string = '';

  constructor(private service: Service) { }

  ngOnInit() {
  }

  onSubmitClick() {
    this.result = false;
    if(!this.name){
      this.resultMsg = "Please specify your name";
      return;
    }

    if(!this.email){
      this.resultMsg = "Please specify your email";
      return;
    }

    if(!this.subject){
      this.resultMsg = "Please specify a subject";
      return;
    }

    if(!this.message){
      this.resultMsg = "Please specify a message";
      return;
    }


    let emailBody =
      `<html><body>Hi,<br/><br/>${this.message}<br/><br/> - ${this.name}<br/></body></html>`;

    this.service.sendMail(this.email, 'info@BramhaScientific.com', this.name, `${this.subject}  (ContactUs - BramhaScientific.com)`, emailBody)
      .subscribe((data: any) => {
        if(data.result){
          this.reset();
        }

        this.result = data.result;
        this.resultMsg = data.message;
      });
  }

  onResetClick() {
    this.reset();
  }

  reset(){
    this.name = "";
    this.email = "";
    this.subject = "";
    this.message = "";
    this.resultMsg = "";
  }
}
