import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class Service {
    constructor(private http: HttpClient) {
    }

    sendMail(fromAddress, toAddress, toName, subject, body) {
        return this.http.post('https://api.chempanda.com/pharma_clients/mail', {
            "FromAddress": fromAddress,
            "ToAddress": toAddress,
            "ToName": toName,
            "Subject": subject,
            "Body": body
        });
    }

    getProducts(){
        return this.http.get('https://api.chempanda.com/pharma_clients/productsByCompanyKey/bs');
    }

    getProductByCasNumber(casNumber){
        return this.http.get('https://api.chempanda.com/pharma_clients/productByCasNumber/'+casNumber);
    }
}