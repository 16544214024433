import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Service } from '../client/service';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent implements OnInit {

  casNo: string;
  trimmedProduceName: string;
  product: any;
  constructor(private route: ActivatedRoute, private service: Service) { 
    this.route.params.subscribe(params => {
      console.log(params);
      this.casNo = params.casNo;
      this.trimmedProduceName = params.trimmedProductName;
    })
  }

  ngOnInit() {
    this.service.getProductByCasNumber(this.casNo).subscribe((data: any) => {
      this.product = data;
      console.log(data);
    });
  }

}
