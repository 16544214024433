import { Component, OnInit } from '@angular/core';
declare var jQuery:any;

@Component({
  selector: 'app-site-layout',
  templateUrl: './site-layout.component.html',
  styleUrls: ['./site-layout.component.scss']
})
export class SiteLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    jQuery('body').removeClass('subpage');
    jQuery('body').addClass('home');
  }

}
