import { Component, OnInit } from '@angular/core';
declare var jQuery:any;

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss']
})
export class AppLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    jQuery('body').removeClass('home');
    jQuery('body').addClass('subpage');
  }

}
