import { Component, OnInit } from '@angular/core';
import { Service } from '../client/service';

import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  productsPerPage: number = 15;
  currentPageIndex: number;
  totalProductsCount: number;
  products: any[];
  productsOfPageIndex: any[];
  constructor(private service: Service, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.spinner.show();
    this.service.getProducts().subscribe((data: any) => {
      this.spinner.hide();
      if(data){
        this.products = data;
        this.currentPageIndex = 0;
        this.totalProductsCount = this.products.length;
        this.updateProductsOfPageIndex();
      }
    });
  }

  onPageChange(event){
    if(!this.products){
      return;
    }

    console.log(event);
    this.currentPageIndex = event.pageIndex;
    this.updateProductsOfPageIndex();
  }

  updateProductsOfPageIndex(){
    let startIndex = this.productsPerPage * this.currentPageIndex;
    let endIndex = startIndex + this.productsPerPage;
    if(endIndex >= this.products.length){
      endIndex = this.products.length - 1;
    }

    this.productsOfPageIndex = this.products.slice(startIndex, endIndex);
  }
}
