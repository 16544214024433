import { Component, OnInit } from '@angular/core';
import { Service } from '../client/service';

@Component({
  selector: 'app-inquiry',
  templateUrl: './inquiry.component.html',
  styleUrls: ['./inquiry.component.scss']
})
export class InquiryComponent implements OnInit {

  name: string;
  phone: string;
  email: string;
  productName: string;
  quantity?: number;
  message: string;

  result: boolean = false;
  resultMsg: string = '';

  constructor(private service: Service) { }

  ngOnInit() {
  }

  onSubmitClick() {
    this.result = false;
    if(!this.name){
      this.resultMsg = "Please specify your name";
      return;
    }

    if(!this.email){
      this.resultMsg = "Please specify your email";
      return;
    }

    if(!this.phone){
      this.resultMsg = "Please specify your phone";
      return;
    }

    if(!this.productName){
      this.resultMsg = "Please specify the product or cas number or item code";
      return;
    }

    if(!this.quantity){
      this.resultMsg = "Please specify the quantity";
      return;
    }

    if(!this.message){
      this.resultMsg = "Please specify a message";
      return;
    }


    let emailBody =
      `<html><body>Hi, <br/><br/>Product Name : ${this.productName}<br/>Quantity(Approx) : ${this.quantity}<br/><br/>${this.message}<br/><br/>- ${this.name}<br/>${this.phone}</body></html>`;

    this.service.sendMail(this.email, 'info@BramhaScientific.com', this.name, `${this.productName}  (Inquiry - BramhaScientific.com)`, emailBody)
      .subscribe((data: any) => {
        if(data.result){
          this.reset();
        }

        this.result = data.result;
        this.resultMsg = data.message;
      });
  }

  onResetClick() {
    this.reset();
  }

  reset(){
    this.name = "";
    this.phone = "";
    this.email = "";
    this.productName = "";
    this.quantity = undefined;
    this.message = "";
    this.resultMsg = "";
  }
}
