import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';

import { routerConfig } from '../routesConfig';
import { ProductsComponent } from './products/products.component';
import { ContactComponent } from './contact/contact.component';

import { NgxSpinnerModule } from 'ngx-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';

import { Service } from './client/service';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { InquiryComponent } from './inquiry/inquiry.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { AboutComponent } from './about/about.component';
import { AppLayoutComponent } from './app-layout/app-layout.component';
import { SiteLayoutComponent } from './site-layout/site-layout.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProductsComponent,
    ContactComponent,
    ProductDetailComponent,
    InquiryComponent,
    AboutComponent,
    AppLayoutComponent,
    SiteLayoutComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    NgxSpinnerModule,
    MatPaginatorModule,
    MDBBootstrapModule.forRoot(),
    RouterModule.forRoot(routerConfig, {anchorScrolling: 'enabled'})
  ],
  providers: [Service],
  bootstrap: [AppComponent]
})
export class AppModule { }
