import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $.getScript('../../../assets/js/startstop-slider.js');
    $.getScript('../../../assets/js/jquery.nivo.slider.js');

    $(window).load(function () {
      $('#slider').nivoSlider(
        // { effect: 'slideInRight'}
     );
    });

    $(window).ready(function () {
      $('#slider').nivoSlider(
         // { effect: 'slideInRight'}
      );
    });
  }
}
