import { Routes } from '@angular/router'
import { HomeComponent } from './app/home/home.component';
import { ProductsComponent } from './app/products/products.component';
import { ContactComponent } from './app/contact/contact.component';
import { ProductDetailComponent } from './app/product-detail/product-detail.component';
import { InquiryComponent } from './app/inquiry/inquiry.component';
import { SiteLayoutComponent } from './app/site-layout/site-layout.component';
import { AppLayoutComponent } from './app/app-layout/app-layout.component';
import { AboutComponent } from './app/about/about.component';

export const routerConfig: Routes = [
    {
        path: '',
        component: SiteLayoutComponent,
        children: [
            { path: '', redirectTo: 'home', pathMatch: 'full' },
            { path: 'home', component: HomeComponent, pathMatch: 'full' }
        ]
    },
    {
        path: '',
        component: AppLayoutComponent,
        children: [
            { path: 'products', component: ProductsComponent },
            { path: 'about', component: AboutComponent },
            { path: 'inquiry', component: InquiryComponent },
            { path: 'contact', component: ContactComponent },
            { path: 'product/:casNo/:trimmedProductName', component: ProductDetailComponent },
        ]
    }
];